<template>
  <main class="bg-white">

    <div class="relative flex">
      <!-- Content -->
      <div class="w-full md:w-2/3">
        <div class="min-h-screen h-full flex flex-col after:flex-1">
          <!-- Header -->
          <div class="flex-1">
            <div class="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
              <NuxtLink :to="localePath('/')">
                <CoreLogo />
              </NuxtLink>
              <MosaicUiDropdownsLanguageSwitcher />
            </div>
          </div>

          <div class="max-w-sm mx-auto px-4 py-8">
            <slot />
          </div>
        </div>
      </div>

      <!-- Image -->
      <div class="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/3 bg-meclee-blue-500" aria-hidden="true"></div>
    </div>

  </main>
</template>

<script setup lang="ts">
const localePath = useLocalePath();
</script>
